export default function SiteLogo({ width, style }) {
  // Original width = 195.6, height = 243.2

  const height = width * (243.2 / 195.6);
  const viewBox = `0 0 ${width * 1.4} ${height * 1.3}`; // よく分からん

  return (
    <svg
      style={{ ...style }}
      display="block"
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      id="svg5"
    >
      <g id="layer1" transform="translate(17.076498,33.400045)">
        <path
          id="path14742"
          style={{
            color: "#000000",
            fontSize: "73.5836px",
            fontFamily: "Bungee",
            InkscapeFontSpecification: '"Bungee, Normal"',
            whiteSpace: "pre",
            opacity: 1,
            fill: "#3b82f6",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: "5.29167",
            InkscapeStroke: "none",
          }}
          d="m -4.9712726,-33.400029 c -1.3459325,0 -2.8229258,0.334566 -3.9217245,1.426787 l -0.00568,0.0052 c -0.00132,0.0013 -0.00281,0.0024 -0.00414,0.0036 -0.00132,0.0013 -0.00228,0.0028 -0.00362,0.0041 l -6.0704299,6.069913 -0.0093,0.0093 5.29e-4,5.29e-4 c -1.124712,1.131431 -1.354439,2.54966 -1.354439,3.849377 V 5.4880372 c 0,1.303402 0.249073,2.6858745 1.255737,3.8183713 l 0.103353,0.1152385 0.117306,0.1038696 c 0.556868,0.4949944 1.174179,0.8061944 1.812809,0.9958044 l -2.66237,2.66237 -0.0093,0.0093 0.001,5.29e-4 c -1.124926,1.131461 -1.354956,2.5475 -1.354956,3.847311 v 8.609293 c 0,1.303403 0.251141,2.685875 1.257805,3.818371 l 0.103352,0.115239 0.115239,0.10387 c 1.132496,1.006663 2.514968,1.257803 3.818371,1.257803 h 23.400639 c 4.849376,0 9.044939,-1.253405 12.165665,-3.947562 3.71e-4,-2.65e-4 6.62e-4,-7.94e-4 10e-4,-0.0011 0.473185,-0.402351 0.900125,-0.840918 1.305863,-1.295012 l 5.331452,-5.286486 c 0.02098,-0.01996 0.04066,-0.0409 0.0615,-0.06098 l 0.359666,-0.356568 -0.0021,-0.0041 c 2.516714,-2.616241 3.844726,-6.120645 3.844726,-9.983887 V -0.21859131 c 0,-4.40009989 -1.430105,-8.25201119 -4.39043,-10.77143569 -0.767189,-0.652927 -1.628954,-1.169154 -2.548166,-1.599385 l 0.02739,-0.0026 2.825148,-2.825667 c 1.099776,-1.099776 1.437639,-2.582241 1.437639,-3.93206 v -8.755538 c 0,-1.302377 -0.348009,-2.757358 -1.464509,-3.832323 -1.074959,-1.116428 -2.527916,-1.462445 -3.830259,-1.462445 z"
        />
        <g
          aria-label={5}
          id="text983"
          style={{
            fontSize: "73.5836px",
            lineHeight: "1.25",
            fontFamily: "Bungee",
            InkscapeFontSpecification: '"Bungee, Normal"',
            whiteSpace: "pre",
            fill: "#ffffff",
            strokeWidth: "0.383117",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
          }}
          transform="translate(-10.092042,23.284081)"
        >
          <path
            d="M 27.784266,-1.058324 H 4.3846798 q -1.3980885,0 -2.0603409,-0.5886688 -0.5886688,-0.6622524 -0.5886688,-2.0603409 v -8.6092813 q 0,-1.398089 0.5886688,-1.986758 0.6622524,-0.662252 2.0603409,-0.662252 H 23.369249 q 0.883004,0 1.398089,-0.367918 0.588669,-0.441502 0.588669,-1.324505 v -2.86976 q 0,-0.883004 -0.588669,-1.250922 -0.515085,-0.441501 -1.398089,-0.441501 H 5.1205158 q -1.3980884,0 -2.0603409,-0.588669 -0.5886688,-0.662252 -0.5886688,-2.060341 v -27.520267 q 0,-1.398089 0.5886688,-1.986758 0.6622525,-0.662252 2.0603409,-0.662252 H 36.835049 q 1.398088,0 1.986757,0.662252 0.662252,0.588669 0.662252,1.986758 v 8.756448 q 0,1.398089 -0.662252,2.060341 -0.588669,0.588669 -1.986757,0.588669 H 19.174984 v 4.782934 h 10.080954 q 5.960271,0 9.418701,2.943344 3.458429,2.943345 3.458429,8.756449 v 10.228121 q 0,5.5923538 -3.899931,8.9036159 -3.826347,3.3112621 -10.448871,3.3112621 z"
            id="path9497"
            style={{
              fill: "#ffffff",
              strokeWidth: "0.383117",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
        </g>
      </g>
    </svg>
  );
}
