import jaMessages from "./messages/ja.json";
import enMessages from "./messages/en.json";

// i18用翻訳ファイル
const i18Messages = {
  ja: jaMessages,
  en: enMessages,
};

export function getI18nMessage(lang) {
  return i18Messages[lang];
}

// 現在、このサイトで用意している言語
const defaultLanguage = { locale: "ja", name: "日本語" };

export const acceptableLanguages = [
  defaultLanguage,
//  { locale: "en", name: "English" },
];

export function getAcceptableLanguage(lang) {
  return acceptableLanguages.filter(l => l.locale === lang)?.[0];
}

// ブラウザ設定の言語を取得
export function getBrowserLanguage() {
  return navigator?.language ?? defaultLanguage.locale;
}

// 引数の言語を受け入れ可能な言語に整理
export function reducePageLanguage(lang) {
  let reduced = lang.toLowerCase();

  // 地域コードを落とす
  if (reduced.indexOf("-") != -1) {
    reduced = reduced.substring(0, reduced.indexOf("-"));
  }

  // 受け入れ可能言語出なかったらデフォルト言語を返す
  if (!acceptableLanguages.map((l) => l.locale).includes(reduced)) {
    return defaultLanguage.locale;
  }

  return reduced;
}
