import { Box, Stack, Container, Typography, SvgIcon } from "@mui/material";
import {
  Face as FaceIcon,
  ElectricBolt as ElectricBoltIcon,
  Visibility as VisibilityIcon,
  Update as UpdateIcon,
  GppGood as GppGoodIcon,
  EmojiEmotions as EmojiSmileIcon,
  AccessibilityNew as AccessibilityIcon,
  People as PeopleIcon,
} from "@mui/icons-material";

export default function Hero() {
  const titleFont = {
    fontFamily: "'Montserrat', sans-serif",
  };

  const bgImage = {
    background:
      "linear-gradient(90deg, rgba(0,31,36,1) 0%, rgba(3,24,63,1) 12%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
  };

  return (
    <>
      <Box sx={{ ...bgImage, pt: 4, pb: 5, color: "#fff" }}>
        <Container maxWidth="lg">
          <Box>
            <Typography variant="h4" component="h1" sx={{ ...titleFont }}>
              5ch Pool
            </Typography>
            <Typography variant="subtitle1">
              有志コミュニティによる高安定、低手数料のカルダノステーキングサービス
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <PoolFeaturesBlock />
          </Box>
        </Container>
      </Box>
    </>
  );
}

function PoolFeatureChip({ icon, label, key }) {
  return (
    <>
      <Box
        key={key}
        sx={{
          borderRadius: "16px",
          backgroundColor: "#c1c1c160",
          py: 1,
          px: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography variant="body1">{label}</Typography>
        </Stack>
      </Box>
    </>
  );
}

function PoolFeaturesBlock() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <PoolFeatureChip icon={<EmojiSmileIcon />} label={"変動手数料 0%!"} />
      <PoolFeatureChip icon={<VisibilityIcon />} label={"24時間/365日監視"} />
      <PoolFeatureChip icon={<UpdateIcon />} label={"稼働率99.9%"} />
      <PoolFeatureChip icon={<GppGoodIcon />} label={"DDoS 対策済"} />
      <PoolFeatureChip icon={<AccessibilityIcon />} label={"シングルプール"} />
    </Box>
  );
}
