import { CardContent, Typography } from "@mui/material";
import { InfoCard, CardTitle } from "./Base";
import { GridView as GridViewIcon } from "@mui/icons-material";

export function LifetimeBlocks({ num }) {
  return (
    <InfoCard>
      <CardContent>
        <CardTitle icon={<GridViewIcon />} label="作成ブロック数" />
        <Typography
          variant="body1"
          align="center"
          sx={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          {num}
        </Typography>
      </CardContent>
    </InfoCard>
  );
}
