import { Subtitles } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import { Typography, Link as MuiLink } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { FormattedTime } from "react-intl";
import { FormattedRelativeTime } from "react-intl";
import { FormattedDate } from "react-intl";

export default function NewsItem({ title, link, date, ogImage }) {
  const pubDate = date ? new Date(date) : null;
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box sx={{ maxWidth: "70%" }}>
            <MuiLink
              href={link}
              rel="noreferrer"
              color="inherit"
              underline="none"
            >
              <Title title={title} />
              <SubTitle date={pubDate} />
            </MuiLink>
          </Box>
          <Box sx={{ maxWidth: "30%" }}>
            <MuiLink
              href={link}
              rel="noreferrer"
              color="inherit"
              underline="none"
            >
              {ogImage?.url ? <NewsImage src={ogImage?.url} /> : null}
            </MuiLink>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

function Title({ title }) {
  return <Typography variant="h6">{title}</Typography>;
}

function SubTitle({ date, source }) {
  const SubTitleTypography = ({ children }) => (
    <Typography>{children}</Typography>
  );

  if (date && source) {
    return (
      <SubTitleTypography>
        <FormattedDate value={date} /> {` - ${source}`}
      </SubTitleTypography>
    );
  } else if (date) {
    return (
      <SubTitleTypography>
        <FormattedDate value={date} />
      </SubTitleTypography>
    );
  } else if (source) {
    return <SubTitleTypography>{`${source}`}</SubTitleTypography>;
  } else {
    return null;
  }
}

function NewsImage({ src }) {
  return (
    <Box
      component="img"
      sx={{
        borderRadius: "8px",
        width: "100%",
        maxHeight: "100px",
        aspectRatio: "1.77/1",
        /*
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          */
      }}
      alt="The house from the offer."
      src={src}
    />
  );
}
