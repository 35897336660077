// i18n 関連のコンテキストなど
import { createContext, useEffect, useState } from "react";
import { getI18nMessage, getAcceptableLanguage } from "./I18n";
import { IntlProvider } from "react-intl";

export const I18nContext = createContext({});

// サイト内で言語を切り替えられるように react-intl のコンテキストプロバイダをラッパ
export function I18nProviderWrapper({ initialLang, children }) {
  //const [language, setLanguage] = useState(getLanguage(getUserLocale()));
  const [language, setLanguage] = useState(getAcceptableLanguage(initialLang));

  const switchLanguage = (lang) => {
    const newLang = getAcceptableLanguage(lang);
    if (!newLang) {
      return;
    }

    if (newLang === language.locale) {
      return;
    }

    setLanguage(newLang);
  };

  useEffect(() => {
    document.documentElement.lang = language.locale;
  }, [language])

  return (
    <I18nContext.Provider
      value={{
        switchLanguage,
        currentLocale: language.locale,
        currentLanguageName: language.name,
      }}
    >
      <IntlProvider
        locale={language.locale}
        messages={getI18nMessage(language.locale)}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
}
