import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Hero from "../components/home/Hero";
import LiveStake from "../components/home/info-cards/LiveStake";
import poolData from "../data/poolData.json";
import Delegators from "../components/home/info-cards/Delegators";
import { LifetimeBlocks } from "../components/home/info-cards/MintedBlocks";
import Costs from "../components/home/info-cards/Costs";
import CardanoPrice from "../components/home/info-cards/CardanoPrice";
import EpochTimer from "../components/home/info-cards/EpochTimer";
import { NewsHeadline } from "../components/home/NewsHeadline";

export default function Home() {
  return (
    <>
      <Hero />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <PoolInfoCards />
        <NewsHeadline />
      </Container>
    </>
  );
}

function PoolInfoCards() {
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 1,
  };

  return (
    <Box sx={flexContainer}>
      <EpochTimer />
      <LiveStake lovelace={poolData.live_stake} />
      <Delegators num={poolData.delegators} />
      <LifetimeBlocks num={poolData.block_minted} />
      <Costs margin={poolData.margin_cost} fixed={poolData.fixed_cost} />
      <CardanoPrice />
    </Box>
  );
}
