import { Stack, Typography, Card } from "@mui/material";

// 各情報カードの元となるスタイル済みの Card
export function InfoCard({ children }) {
  return (
    <>
      <Card elevation={0} variant="outlined" sx={{flexGrow: 1}}>
        {children}
      </Card>
    </>
  );
}

export function CardTitle({ icon, label }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon}
      <Typography variant="subtitle1">{label}</Typography>
    </Stack>
  );
}
