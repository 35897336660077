const zeroEpochUnixTimestamp = 1506203091; // sec
export const slotsPerEpoch = 432000; // sec

export function getEpochStartUnixTimestamp(epoch, millisecond = true) {
  return (
    (epoch * slotsPerEpoch + zeroEpochUnixTimestamp) * (millisecond ? 1000 : 1)
  );
}

let epochMemo = null;
export function getCurrentEpoch() {
  const startEpoch = epochMemo ?? 350;
  const nowTime = new Date().getTime();

  let tempEpoch = startEpoch;
  while (
    (nowTime - getEpochStartUnixTimestamp(tempEpoch, true)) / 1000 >
    slotsPerEpoch
  ) {
    tempEpoch += 1;
  }

  epochMemo = tempEpoch;
  return tempEpoch;
}
