import { useEffect, useState } from "react";
import { Box, CardContent, Typography, LinearProgress } from "@mui/material";
import { InfoCard, CardTitle } from "./Base";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import {
  getCurrentEpoch,
  getEpochStartUnixTimestamp,
  slotsPerEpoch,
} from "../../../lib/cardano/epochShelly";
import { FormattedMessage } from "react-intl";

// 補助関数

// 今現在のエポックからの経過秒を返す
const getPassedSec = (epoch) =>
  (new Date().getTime() - getEpochStartUnixTimestamp(epoch, true)) / 1000;

// 経過秒から現在のエポック進行度を得る
const getProgress = (passedSec) =>
  Math.round((passedSec / slotsPerEpoch) * 100);

// 経過秒からエポック内の残り病を求める
const getLeftSec = (passedSec) => Math.floor(slotsPerEpoch - passedSec);

// 残り秒数から残り各時間を求める
const getLeftTime = (leftSec) => {
  let sec = leftSec;
  const oneDaySec = 86400;
  const oneHourSec = 3600;
  const oneMinSec = 60;
  const day = leftSec > oneDaySec ? Math.floor(leftSec / oneDaySec) : 0;
  sec -= day * oneDaySec;
  const hour = sec > oneHourSec ? Math.floor(sec / oneHourSec) : 0;
  sec -= hour * oneHourSec;
  const min = sec > oneMinSec ? Math.floor(sec / oneMinSec) : 0;
  sec -= min * oneMinSec;
  return {
    leftSec,
    day,
    hour,
    min,
    sec,
  };
};

// 残り時間から適切なメッセージを取得する
const getLeftMessage = (leftTime) => {
  if (leftTime?.leftSec >= 86400) {
    return (
      <FormattedMessage id={"epochTimer.leftTimeFull"} values={leftTime} />
    );
  } else if (leftTime?.leftSec >= 3600) {
    return (
      <FormattedMessage id={"epochTimer.leftTimeHMS"} values={leftTime} />
    );
  } else if (leftTime?.leftSec >= 60) {
    return (
      <FormattedMessage id={"epochTimer.leftTimeMS"} values={leftTime} />
    );
  } else {
    return (
      <FormattedMessage id={"epochTimer.leftTimeFull"} values={leftTime} />
    );
  }
};

export default function EpochTimer() {
  const [epoch, setEpoch] = useState(getCurrentEpoch());
  const [passedSec, setPassedSec] = useState(getPassedSec(epoch));

  useEffect(() => {
    const timerId = setInterval(() => {
      const currentEpoch = getCurrentEpoch();
      const newPassedSec = getPassedSec(currentEpoch);
      setEpoch(currentEpoch);
      setPassedSec(newPassedSec);

      return () => {
        clearInterval(timerId);
      };
    }, 1000);
  }, []);

  const progress = getProgress(passedSec);
  const leftSec = getLeftSec(passedSec);

  return (
    <InfoCard>
      <CardContent>
        <CardTitle
          icon={<WatchLaterOutlinedIcon />}
          label={`エポック ${epoch}`}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Typography variant="caption" >
          {getLeftMessage(getLeftTime(leftSec))}
        </Typography>
      </CardContent>
    </InfoCard>
  );
}
