import { CardContent, Typography } from "@mui/material";
import { InfoCard, CardTitle } from "./Base";
import { Handshake as HandshakeIcon } from "@mui/icons-material";

export default function LiveStake({ lovelace }) {
  const ada = Math.floor(parseInt(lovelace) / 1000000).toLocaleString();
  return (
    <InfoCard>
      <CardContent>
        <CardTitle icon={<HandshakeIcon />} label="委任量" />
        <Typography
          variant="body1"
          align="center"
          sx={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          {ada} ₳
        </Typography>
      </CardContent>
    </InfoCard>
  );
}
