import { useEffect } from "react";

export default function CardanoPrice(){
  const scriptSrc = "https://bitgur.com/static/widgets/ticker/ticker.js";
  const targetClassName = "bw-ticker";
  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src = scriptSrc;
    const wrapper = document.getElementsByClassName(targetClassName)[0];
    wrapper.appendChild(scriptElement);
  }, []);
  return (
    <>
      <div
        className={`${targetClassName}`}
        style={{ width: "150px", minHeight: "80px" }}
        data-coin="ADA"
        data-theme="light"
        data-unit="JPY"
      >
      </div>
    </>
  );
}
