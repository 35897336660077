import Layout from "./components/Layout";
import Home from "./pages/home";
import NoMatch from "./pages/nomach";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { reducePageLanguage } from "./i18n/I18n";
import { I18nProviderWrapper } from "./i18n/I18nContext";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";

function LanguageChecker() {
  // 下位コンポーネントをレンダリング中にコンテキストを更新するとエラーになるので最初のレンダリング時に初期言語を決める

  // URL のサブパスから言語を決める
  const { language } = useParams();

  // 受け入れ可能言語に絞る
  const reducedLang = reducePageLanguage(language);
  if (language !== reducedLang) {
    console.log(`invalid language=${language}`);
    // 本当は言語移行のパスも保持したまま移動するのがよい?
    return <Navigate to={`/${reducedLang}`} />;
  }

  return (
    <I18nProviderWrapper initialLang={reducedLang}>
      <CssBaseline />
      <Layout>
        <Outlet />
      </Layout>
    </I18nProviderWrapper>
  );
}

function App() {
  const navigate = useNavigate();

  // 初期の言語設定について
  // サブパスで指定された言語を優先するが、
  // 指定されていない場合はブラウザに設定された言語でまずは Navigate to し、
  // ナビゲート先(LanguageChecker)で受け入れ可能言語かをチェックしている
  const initialLanguage = navigator?.language;

  return (
    <>
      <Routes>
        {/* React router の Navigate によるURL変更は HTTP 30x のようなリダイレクトではないので SEO には影響しないと思われる */}
        <Route index element={<Navigate to={`/${initialLanguage}`} />} />
        {/* 受け入れ可能言語か確認し、i18コンテキストでラップする */}
        <Route path="/:language" element={<LanguageChecker />}>
          <Route path="" element={<Home />} />
          {/*
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="posts/:id" element={<Posts />}></Route>
          */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

export default App;
