import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiLink from "@mui/material/Link";
import { acceptableLanguages } from "../i18n/I18n";
import { I18nContext } from "../i18n/I18nContext";
import { Link, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import {
  Twitter as TwitterIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import SiteLogo from "./Logo";

// Layout 部分だけのカラー変更
const laytouTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#f4f4f4",
      light: "#ffffff",
      dark: "#c1c1c1",
    },
  },
});

export default function Layout({ children }) {
  const { currentLocale } = useContext(I18nContext);
  return (
    <>
      <ThemeProvider theme={laytouTheme}>
        <AppBar elevation={0} sx={{ background: "#ffffff" }}>
          {/* Toolbar は子要素をインラインに並べる（flex-direction: row） */}
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to={`/${currentLocale}`}>
                <SiteLogo width={40} />
              </Link>
            </Typography>
            <LanguageSwitcher />
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Toolbar /> {/* 背後にメインコンテンツが隠れるのを予防用の空 Toolbar */}
      {children}
      <Footer />
    </>
  );
}

function LanguageSVG() {
  return (
    <svg viewBox="0 0 20 20" width="20" height="20" aria-hidden="true">
      <path
        fill="currentColor"
        d="M19.753 10.909c-.624-1.707-2.366-2.726-4.661-2.726-.09 0-.176.002-.262.006l-.016-2.063 3.525-.607c.115-.019.133-.119.109-.231-.023-.111-.167-.883-.188-.976-.027-.131-.102-.127-.207-.109-.104.018-3.25.461-3.25.461l-.013-2.078c-.001-.125-.069-.158-.194-.156l-1.025.016c-.105.002-.164.049-.162.148l.033 2.307s-3.061.527-3.144.543c-.084.014-.17.053-.151.143.019.09.19 1.094.208 1.172.018.08.072.129.188.107l2.924-.504.035 2.018c-1.077.281-1.801.824-2.256 1.303-.768.807-1.207 1.887-1.207 2.963 0 1.586.971 2.529 2.328 2.695 3.162.387 5.119-3.06 5.769-4.715 1.097 1.506.256 4.354-2.094 5.98-.043.029-.098.129-.033.207l.619.756c.08.096.206.059.256.023 2.51-1.73 3.661-4.515 2.869-6.683zm-7.386 3.188c-.966-.121-.944-.914-.944-1.453 0-.773.327-1.58.876-2.156a3.21 3.21 0 011.229-.799l.082 4.277a2.773 2.773 0 01-1.243.131zm2.427-.553l.046-4.109c.084-.004.166-.01.252-.01.773 0 1.494.145 1.885.361.391.217-1.023 2.713-2.183 3.758zm-8.95-7.668a.196.196 0 00-.196-.145h-1.95a.194.194 0 00-.194.144L.008 16.916c-.017.051-.011.076.062.076h1.733c.075 0 .099-.023.114-.072l1.008-3.318h3.496l1.008 3.318c.016.049.039.072.113.072h1.734c.072 0 .078-.025.062-.076-.014-.05-3.083-9.741-3.494-11.04zm-2.618 6.318l1.447-5.25 1.447 5.25H3.226z"
      ></path>
    </svg>
  );
}

function LanguageSwitcher() {
  const location = useLocation();
  const navigate = useNavigate();
  const { switchLanguage, currentLanguageName } = useContext(I18nContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectLanguage = (locale) => (event) => {
    switchLanguage(locale);

    // URL を再構築
    console.log(location);
    const [_, oldLocale, ...left] = location.pathname.split("/");
    navigate(`/${locale}/${left.join("/")}`);

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="inherit"
        startIcon={<LanguageSVG />}
        endIcon={<KeyboardArrowDownIcon />}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        <Typography valiant="button" component="p">
          {currentLanguageName}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {acceptableLanguages.map((l) => (
          <MenuItem key={l.locale} onClick={handleSelectLanguage(l.locale)}>
            {l.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function Footer() {
  return (
    <footer>
      <Box
        sx={{
          mt: 4,
          py: 2,
          flexGrow: 1,
          color: "#fff",
          background: "#04154a",
        }}
      >
        <Container maxWidth="lg" sx={{ pt: 2 }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            連絡先
          </Typography>
          <ul style={{ listStyle: "none" }}>
            <li>
              <MuiLink
                href="https://twitter.com/5chPool"
                rel="noreferrer"
                color="inherit"
                underline="none"
              >
                <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                  <TwitterIcon />
                  <Typography variant="body1">Twitter</Typography>
                </Stack>
              </MuiLink>
            </li>
            <li>
              <MuiLink
                href="mailto:5chstakepool@gmail.com"
                rel="noreferrer"
                color="inherit"
                underline="none"
              >
                <Stack direction="row" spacing={1}>
                  <EmailIcon />
                  <Typography variant="body1">Eメール</Typography>
                </Stack>
              </MuiLink>
            </li>
          </ul>
        </Container>
      </Box>
    </footer>
  );
}
