import { Typography } from "@mui/material";
import { Stack, Box } from "@mui/system";
import NewsItem from "./news/NewsItem";
import articles from "../../data/news/headline.json";

const numPerPage = 10;

export function NewsHeadline() {
  return (
    <>
      <Box sx={{ my: 4 }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Cardano ニュースヘッドライン
        </Typography>
        <Stack spacing={2}>
          {articles.slice(0, numPerPage).map((article, i) => {
            //console.log(article);
            return (
              <NewsItem
                title={article?.title}
                date={article?.pubDate}
                ogImage={article?.ogImage}
                link={article?.link}
                key={i}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
