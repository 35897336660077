import { CardContent, Typography } from "@mui/material";
import { InfoCard, CardTitle } from "./Base";
import { MonetizationOn as MonetizationOnIcon } from "@mui/icons-material";

export default function Costs({ margin, fixed }) {
  const fixedCost = (parseInt(fixed) / 1000000).toLocaleString();
  return (
    <InfoCard>
      <CardContent>
        <CardTitle icon={<MonetizationOnIcon />} label="プール手数料" />
        <Typography
          variant="body1"
          align="center"
          sx={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          {`${margin} % + ${fixedCost} ₳`}
        </Typography>
      </CardContent>
    </InfoCard>
  );
}
